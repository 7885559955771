import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
interface Props {
    title?: string;
    name: string;
    position?: string;
    paragraph: any;
}

const StyledHero = styled.div`
    padding: 0em 1em;
    h1 {
        background: radial-gradient(142.84% 80.36% at 0% 100%, #00526f 0%, #218fb6 100%)
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    .doctor-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            margin-top: 2em;
        }

        span {
            margin-top: 0.4em;
        }
        div {
            margin: 3em 0em 0em;
            box-shadow: 0px 16px 64px rgba(0, 82, 111, 0.2);
            border-radius: 180px;
            height: 470px;
        }
    }
    p > span {
        font-weight: 700;
        color: ${colors.blueMedium};
    }
    .paragraph {
        border-top: 1px solid ${colors.blueMedium};
        padding-top: 2em;
        margin-top: 2em;
    }
    @media ${device.tablet} {
        padding: 1em 2em;
        .flex-container {
            display: flex;
        }
        .doctor-image-container {
            min-width: 45vw;
        }
        .text-container {
            padding: 2em;
            margin-top: 4em;
        }
    }
    @media ${device.laptop} {
        max-width: 1042px;
        margin: 0 auto;
        .flex-container {
            display: flex;
        }
        .doctor-image-container {
            min-width: 25vw;
            span {
                padding: 0em 2em;
            }
            div {
                height: 544px;
                width: 424px;
            }
        }

        .text-container {
            padding: 2em 2em 2em 14em;
            min-width: 300px;
            margin: 6em 0em 0em 5em;
            position: relative;
            > div {
                position: absolute;
                left: -4em;
                top: 10em;
                width: 220px;
                background: ${colors.blueMedium};
                height: 1px;
            }
        }
        .paragraph {
            border-top: none;
        }
    }
    @media ${device.laptopL} {
        max-width: 1200px;
        .text-container {
            > div {
                position: absolute;
                left: -1em;
                top: 12em;
                width: 220px;
                background: ${colors.blueMedium};
                height: 1px;
            }
        }
    }
    @media ${device.desktop} {
        max-width: 1342px;
    }
`;

export const AboutHero: React.FC<Props> = ({ title, name, position, paragraph }) => {
    return (
        <StyledHero>
            <h1>{title}</h1>
            <div className="flex-container">
                <div className="doctor-image-container">
                    <StaticImage
                        className="doctor-image"
                        src="../images/doctor.jpg"
                        alt="doctor Nedeau"
                        placeholder="none"
                        quality={100}
                    />

                    <p style={{ textAlign: 'center', fontWeight: '700', color: `${colors.pink}` }}>
                        {name}
                    </p>
                    <span style={{ textAlign: 'center', fontWeight: '700', color: `#878787` }}>
                        {position}
                    </span>
                </div>
                <div className="text-container">
                    <div></div>
                    <p className="paragraph">{paragraph}</p>
                </div>
            </div>
        </StyledHero>
    );
};
