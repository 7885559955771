import React from 'react';
import styled from '@emotion/styled';
import { device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledAboutList = styled.div`
    padding: 0em 1em;
    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        > div {
            margin: 1em 0em;
        }
    }
    @media ${device.tablet} {
        padding: 0em 2em;
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 2em 0em;
            > p {
                padding: 0em 2em;
            }
            > div {
                height: 64px;
                min-width: 64px;
                margin: 0em 0em;
            }
        }
    }
    @media ${device.tablet} {
        margin: 0 auto;
    }
    @media ${device.laptop} {
        max-width: 1040px;
        margin: 4em auto;
    }
`;

export const AboutList = ({ list }: any) => {
    return (
        <StyledAboutList>
            {list.map((item: any) => (
                <div key={item}>
                    <StaticImage
                        src="../images/svgs/check-blue.svg"
                        alt="blue check mark"
                        placeholder="none"
                        quality={100}
                    />
                    <p>{item}</p>
                </div>
            ))}
            {/* <div>
                <StaticImage
                    src="../images/svgs/check-blue.svg"
                    alt="blue check mark"
                    placeholder="none"
                    quality={100}
                />
                <p>
                    Dr. Christine Nedeau is on a mission to help others achieve life-long good
                    health. Dr. Nedeau believes that the key to longevity begins with a primary care
                    medical home that supports a healthy lifestyle, treats the every-day-health
                    concerns, and a care team who simply know their patients well. Dr. Nedeau wants
                    to make healthcare what it should be, and she wants to provide the type of care
                    to her patients that she would want from her own doctor.
                </p>
            </div>
            <div>
                <StaticImage
                    src="../images/svgs/check-blue.svg"
                    alt="blue check mark"
                    placeholder="none"
                    quality={100}
                />
                <p>
                    Dr. Nedeau received her bachelor’s degree in biology and psychology from Baylor
                    University then went on to graduate studies in genetics and immunology at
                    Creighton University. She received her medical degree from the Cebu Institute of
                    Medicine in Cebu City, Philippines and completed the family medicine residency
                    training program in St. Joseph Hospital & Medical Center, Phoenix, Arizona. Dr.
                    Nedeau has extensive training in integrative, functional, and precision medicine
                    and is completing advanced certification in endocrinology and metabolism. She is
                    working towards board certification in anti-aging and regenerative
                    medicine/precision medicine.
                </p>
            </div>
            <div>
                <StaticImage
                    src="../images/svgs/check-blue.svg"
                    alt="blue check mark"
                    placeholder="none"
                    quality={100}
                />
                <p>
                    Dr. Nedeau began her career in Liberty, Missouri, practicing traditional
                    community family medicine which included obstetrics, hospital medicine, and the
                    outpatient clinic. In 2011, she joined Cerner Corporation and spearheaded the
                    growth of its’ on-site Healthe Clinic and wellness programs. She worked closely
                    with the innovation and engineering teams until she left the corporate world and
                    the conventional healthcare system behind to start her own practice in 2020.
                </p>
            </div> */}
        </StyledAboutList>
    );
};
