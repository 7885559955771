import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledAboutTeam = styled.div`
    h3 {
        margin: 0.25em 0em;
        font-size: 36px;
        > span {
            color: ${colors.pink};
            font-family: 'Bodini MT';
            font-style: italic;
        }
        color: ${colors.blueLight};
    }
    .blue-box {
        margin: 2em 0em 0em;
        position: relative;
        padding: 2em 0em;
        max-height: 375px;
        z-index: -2;
        background: ${colors.blueGradient};
        > div {
            right: -12em;
            bottom: 5em;
            z-index: -1;
            img {
                z-index: -1;
            }
        }
        > p {
            padding: 2.1em 1em;
            color: ${colors.white};
            font-family: 'Bodini MT';
            font-style: italic;
            font-size: 18px;
            font-weight: 600;
        }
    }
    section {
        padding: 1em;
        z-index: 0;
        div > p {
            margin: 1em 0em;
        }
    }
    @media ${device.laptop} {
        max-width: 740px;
        margin: 4em auto;
        section > div {
            display: flex;
            margin-top: 2em;
            h3 {
                padding-right: 1.5em;
            }
        }
        .blue-box {
            border-radius: 64px;
            > p {
                padding: 2em 11em;
            }
            > div {
                right: -25em;
                bottom: 5em;
                z-index: -1;
                img {
                    z-index: -1;
                }
            }
        }
    }
`;

export const AboutTeam: React.FC = () => {
    return (
        <StyledAboutTeam>
            <div className="blue-box">
                <p>
                    “Health is so precious and fragile, and it’s often taken for granted until
                    disease happens, but fortunately, the human body and mind are resilient! I teach
                    people how to prevent, reverse, (and daresay, cure) their medical conditions.
                    It’s a privilege to guide my patients down a new path towards longevity!”
                </p>
                <StaticImage
                    src="../images/svgs/white-star.svg"
                    alt="white star"
                    placeholder="none"
                    quality={100}
                />
            </div>
            <section>
                <div>
                    <h3>
                        <span>Our </span>
                        <br />
                        Team
                    </h3>
                    <p>
                        Our team is dedicated to identifying the causes for chronic disease with a
                        comprehensive, holistic approach to health and wellness that considers
                        physical, spiritual, mental, emotional, and environmental influences. We
                        will develop an individualized treatment plan that may include conventional
                        and complementary methods.
                    </p>
                </div>
            </section>
        </StyledAboutTeam>
    );
};
