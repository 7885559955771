import { AboutHero } from '@/About-Hero';
import { AboutList } from '@/About-List';
import { AboutTeam } from '@/About-Team';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '@/Seo';

const mainBios = [
    {
        name: 'Dr. Christine Nedeau',
        position: 'Founder & CEO, Family Medicine, Functional & Integrative Medicine',
        paragraph: (
            <>
                <span>Dr. Christine Nedeau, MD, FAAFP</span> is a board-certified family physician
                and the founder and CEO of Bloom La Vie Health. She moved to the Kansas City area in
                2002 after completing her family medicine residency training in Phoenix, Arizona.
                Dr. Nedeau received a BA in biology & psychology from Baylor University then
                attended Creighton Graduate School to study genetics and immunology
            </>
        ),
    },
];

const listBios = {
    nedeau: [
        `Dr. Christine Nedeau, MD, FAAFP is on a mission to help others achieve life-long good
    health. Dr. Nedeau believes that the key to longevity begins with a primary care
    medical home that supports a healthy lifestyle, treats the every-day-health
    concerns, and a care team who simply know their patients well. Dr. Nedeau wants
    to make healthcare what it should be, and she wants to provide the type of care
    to her patients that she would want from her own doctor.`,
        ` Dr. Nedeau received her bachelor’s degree in biology and psychology from Baylor
    University then went on to graduate studies in genetics and immunology at
    Creighton University. She received her medical degree from the Cebu Institute of
    Medicine in Cebu City, Philippines and completed the family medicine residency
    training program in St. Joseph Hospital & Medical Center, Phoenix, Arizona. Dr.
    Nedeau has extensive training in integrative, functional, and precision medicine
    and is completing advanced certification in endocrinology and metabolism. She is
    working towards board certification in anti-aging and regenerative
    medicine/precision medicine.`,
        ` Dr. Nedeau began her career in Liberty, Missouri, practicing traditional
    community family medicine which included obstetrics, hospital medicine, and the
    outpatient clinic. In 2011, she joined Cerner Corporation and spearheaded the
    growth of its’ on-site Healthe Clinic and wellness programs. She worked closely
    with the innovation and engineering teams until she left the corporate world and
    the conventional healthcare system behind to start her own practice in 2020.`,
    ],
};

// In 2013 he joined Cerner to help start up
//                 an onsite clinic caring for the employees of the Childrens Mercy Hospital System.
//                 During this time, he helped pilot several educational components including a monthly
//                 seminar on type II diabetes for a large panel of the clinic patients. In 2016 he
//                 transitioned to the onsite Healthe Clinics within Cerner, where he practiced until
//                 2021.

const AboutUs: Page = () => (
    <main>
        <DefaultLayout title="About Us" about>
            <Seo
                title="About Us | Bloom La Vie Health"
                description="Dr. Christine Nedeau, MD, FAAFP is a board-certified family physician and the founder and CEO of Bloom La Vie Health. She moved to the Kansas City area in 2002 after completing her family medicine residency training in Phoenix, Arizona."
            />
            <AboutHero
                title="About Us"
                name={mainBios[0].name}
                position={mainBios[0].position}
                paragraph={mainBios[0].paragraph}
            />
            <AboutList list={listBios.nedeau} />
            <AboutTeam />
        </DefaultLayout>
    </main>
);

export default AboutUs;
